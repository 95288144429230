<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">新建项目现场环境照片</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item
                    prop="XJXMID"
                    label="项目名称"
                    class="from-item-block"
                >
                    <el-select
                        v-model="formdata.dataDic.XJXMID"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in xjxmData"
                            :key="`${index}`"
                            :value="item.ID"
                            :label="item.XMMC"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="JCWZ"
                    label="监测点"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.JCWZ"
                        :disabled="!isEdit"
                        :maxlength="50"
                    ></el-input>
                </el-form-item>
                <!-- <el-form-item
                    prop="PG"
                    label="评估(是否按照批准的方案进行建设)"
                    class="from-item-block"
                >
                    <el-select
                        v-model="formdata.dataDic.PG"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in PGList"
                            :key="`${index}`"
                            :value="item.CODE"
                            :label="item.LABEL"
                        ></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item
                    prop="XJXMKZQK"
                    label="新建项目控制情况"
                    class="from-item-block"
                >
                    <el-select
                        v-model="formdata.dataDic.XJXMKZQK"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in XJXMKZQKList"
                            :key="`${index}`"
                            :value="item.CODE"
                            :label="item.LABEL"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="SFWG"
                    label="是否完工"
                    class="from-item-block"
                >
                    <el-select
                        v-model="formdata.dataDic.SFWG"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in SFWGList"
                            :key="`${index}`"
                            :value="item.CODE"
                            :label="item.LABEL"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="SJWGSJ"
                    label="实际完工时间"
                    class="from-item-block"
                >
                    <el-date-picker
                        v-model="formdata.dataDic.SJWGSJ"
                        type="date"
                        placeholder="选择实际完工时间"
                        value-format="yyyy-MM-dd"
                        :disabled="!enableEditDate || !isEdit"
                        @change="autoSaveOnChange"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item prop="SM" label="备注" class="from-item-block">
                    <el-input
                        v-model="formdata.dataDic.SM"
                        resize="none"
                        type="textarea"
                        :disabled="!isEdit"
                        :autosize="{ minRows: 2, maxRows: 6 }"
                        :maxlength="500"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="TXSJ"
                    label="填写时间"
                    class="from-item-block"
                >
                    <el-date-picker
                        v-model="formdata.dataDic.TXSJ"
                        type="date"
                        placeholder="选择填写时间"
                        value-format="yyyy-MM-dd"
                        :disabled="!isEdit"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="现场环境照片" class="from-item-block">
                    <basic-upload
                        ref="uploadFile"
                        :itemId="itemId"
                        :metaData.sync="formdata.metaJson"
                        basicType="picture"
                        :isEdit="isEdit"
                        :maxSize="500"
                    ></basic-upload>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "xjxmgzjl",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "90203",
                dataDic: {
                    XJXMID: "",
                    PG: "",
                    XJXMKZQK: "",
                    SFWG: "",
                    SJWGSJ: "",
                    SM: "",
                    TXSJ: "",
                },
            },
            rules: {
                bhgljgmc: [
                    {
                        required: true,
                        message: "请填写保护管理机构名称",
                        trigger: "blur",
                    },
                ],
            },
            xjxmData: [],
            PGList: [],
            SFWGList: [],
            XJXMKZQKList: [],
        };
    },
    watch: {
        "formdata.dataDic": {
            deep: true,
            handler: function() {
                if (this.formdata.dataDic.SFWG) {
                    this.formdata.dataDic.SFWG = String(
                        this.formdata.dataDic.SFWG
                    );
                }
            },
        },
    },
    mounted() {
        this.getXjxmList();
        this.GetEnum();
    },
    methods: {
        ...mapActions(["GetGLXList", "getAllEnumInfobytableID"]),
        // 获得考古项目列表
        async getXjxmList() {
            let res = await this.GetGLXList({
                itemcode: "90201",
                lm: "jcsj",
            });
            this.xjxmData = res.ResultValue || [];
        },
        async GetEnum() {
            let res = await this.getAllEnumInfobytableID({
                bid: "90203",
            });
            let enumList = res || [];
            enumList.forEach((v) => {
                if (v.COLUMNID === "PG") {
                    this.PGList = v.dataItems;
                }
                if (v.COLUMNID === "SFWG") {
                    this.SFWGList = v.dataItems;
                }
                if (v.COLUMNID === "XJXMKZQK") {
                    this.XJXMKZQKList = v.dataItems;
                }
            });
        },
        GetDataNext() {
            // 服务返回为数字类型  选择器需字符串 故需转换
            this.formdata.dataDic.SFWG = String(this.formdata.dataDic.SFWG);
        },
    },
};
</script>

<style></style>
